<template>
  <div class="container">
    <div class="content" v-html="language === 'cn' ? newsInfo.cnContent : newsInfo.enContent"></div>
  </div>
</template>

<script>
import { jsonRequest } from '../../../utils/request'
import { PathEnum } from '../../../enum/pathEnum'

export default {
  name: 'newsInfo',
  components: {},
  computed: {
    language() {
      return this.$store.state.language
    }
  },
  data() {
    return {
      newsInfo: {}
    }
  },
  watch: {
    $route(to) {
      if (to.params && to.params.id) {
        this.getNewsList(to.params.id)
      } else {
        this.$router.push({ path: '/' })
      }
    }
  },
  created() {},
  mounted() {
    const { params } = this.$route
    if (params && params.id) {
      this.getNewsList(params.id)
    }
  },
  methods: {
    async getNewsList(id) {
      const { data } = await jsonRequest(PathEnum.NEWS)
      this.newsInfo = data.list[id - 1]
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding-top: 20px;
  width: 950px;
  margin: 0 auto;
  font-weight: 400;

  ::v-deep .content {
    font-size: 16px;
    white-space: pre-line;
    text-align: justify;
    text-indent: 2em;
    line-height: 1.7;

    h1 {
      font-size: 28px;
      line-height: 28px;
      font-weight: bold;
      text-align: center;
      margin: 30px 0;
      white-space: pre-line;
    }

    h2 {
      font-size: 20px;
      line-height: 20px;
      font-weight: bold;
      text-align: center;
      margin: 40px 0 26px;
    }
    a:visited {
      color: #ffffff;
    }
    img {
      margin: 0 auto;
      display: block;
      max-width: 950px;
      height: auto;
    }
    video {
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
